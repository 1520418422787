var clients_bh;
jQuery(document).ready(function($) {

	// Traducció magnific popup
	$.extend(true, $.magnificPopup.defaults, {
	  tClose: 'Tanca (Esc)', 
	  tLoading: 'Carregant...', 
	  gallery: {
	    tPrev: 'Anterior (Tecla fletxa esquerra)', 
	    tNext: 'Següent (Tecla fletxa dreta)',
	    tCounter: '%curr% de %total%'
	  },
	  image: {
	    tError: '<a href="%url%">La imatge</a> no s\'ha pogut carregar.'
	  },
	  ajax: {
	    tError: '<a href="%url%">El contingut</a> no s\'ha pogut carregar.' 
	  }
	});

	/*
	$('.btn-add-to-cart').magnificPopup({
		type: 'ajax'
	});
	*/

	$('.thumbnail-popup').magnificPopup({
		type: 'image'
	});

	$('body').on('focusout','#qty',function(){
		var preu = $(this).val()*$('#preu').text();
		$("#preu-total").val(preu.toFixed(2));
	})

	$("a[data-target=#product-modal]").on('click',function(ev) {
		ev.preventDefault();
		var target = $(this).attr("href");
		$("#product-modal .modal-content").load(target, function() { 
			$("#product-modal").modal("show"); 
		});
	});

	$('body').on('submit',"#form-product",function(e){
		e.preventDefault();
		var $btn = $(this).find('button').button('loading');
		$(this).ajaxSubmit({
			success: function(data){
				$btn.button('reset');
				$("html, body").animate({ scrollTop: 0 }, 200);
				if(data.error) {
					$("#form-product").append('<div class="alert alert-warning alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button><strong>Error: </strong>'+data.error+'</div>');
				} else {
					$(".badge-cistell").text(data.total);
					//$('.btn-add-to-cart').magnificPopup('close');
					$("#product-modal").modal('hide');
					//$('.col-productes').prepend('<div class="alert alert-success alert-dismissible" role="alert"><button type="button" class="close" data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>Producte afegit al cistell ouyea</div>');
					var $popover = $(".btn-cistell").popover({
						content: 'S\'han afegit <strong>'+data.qty+'</strong> '+data.unit+' de <strong>'+data.producte+'</strong> a la comanda',
						placement: 'bottom',
						trigger: 'manual',
						html: true
					}).popover('show');
					setTimeout(function(){ $popover.popover('hide'); }, 4000);
				}
			}
		});
		return false;
	}); 

	if($(".typeahead").length && clients){

		$('.typeahead').typeahead({
			source: clients,
			display: 'name',
        	afterSelect: function(item) {
				$(".field-id").val(item.id);
        	}
		});

	}

});